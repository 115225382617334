















































import Vue from 'vue'
import NewCampaignLayout from '@/components/ADSManager/NewCampaignLayout.vue'
import Card from '@/components/Card.vue'
import BaseButton from '@/components/BaseComponents/BaseButton/BaseButton.vue'
import AudienceCard from '@/components/ADSManager/NewCampaign/Audience/AudienceCard.vue'
import BasePagePreloader from '@/components/BasePagePreloader.vue'
import { mapActions, mapGetters } from 'vuex'

export default Vue.extend({
  name: 'Audience',
  components: {
    Card,
    BaseButton,
    NewCampaignLayout,
    AudienceCard,
    BasePagePreloader
  },
  props: {
    campaign_id: {
      required: true
    },
    campaign: {
      required: false
    }
  },

  data () {
    return {
      new_audiences: [],
      editable_audiences: [],
      loading: true
    }
  },
  created () {
    const promises = [
      this.load_custom_audiences(this.campaign_id),
      this.load_location_types(this.campaign_id),
      this.load_campaign_objective()
    ]
    if (!this.campaign) {
      promises.push(this.load_campaign({ campaignId: this.campaign_id, params: {} }))
    }
    if (this.getLocalAudiencesData) {
      this.new_audiences = this.getLocalAudiencesData
    } else {
      promises.push(this.load_campaign_targeting(this.campaign_id)
        .then(() => {
          this.new_audiences = this.getTargetingAudiencesData
          if (this.new_audiences.length === 0) {
            this.add_new_audience()
          }
        }))
    }
    Promise.all(
      promises
    ).then(() => {
      this.loading = false
    })
  },
  computed: {
    ...mapGetters('adsManager', [
      'getCampaign',
      'getTargetingAudiencesData',
      'getLocalAudiencesData'
    ])
  },
  methods: {
    ...mapActions('adsManager', [
      'load_custom_audiences',
      'load_campaign',
      'load_location_types',
      'create_audience',
      'load_campaign_targeting',
      'load_campaign_objective',
      'set_local_audiences_data'
    ]),
    ...mapActions('notifications', ['addNotification', 'addExceptions']),
    audience_editable (id) {
      return this.editable_audiences.findIndex(i => i === id) !== -1
    },
    save_audience (audience) {
      if ((!audience.audiences || !audience.audiences.filter(a => a.is_included).length) && (!audience.locations || !audience.locations.filter(l => l.is_included).length)) {
        this.addNotification({ body: 'Targeting should include audience or location', type: 'danger' })
        return
      }
      const i = this.new_audiences.findIndex(a => a.id === audience.id)
      if (i !== -1) {
        this.new_audiences[i] = audience
      } else {
        this.new_audiences.push(audience)
      }
      this.toggle_audience_editable(audience.id)
      this.set_local_audiences_data(this.new_audiences)
    },
    toggle_audience_editable (audienceId) {
      const i = this.editable_audiences.findIndex(a => a === audienceId)
      if (i !== -1) {
        this.editable_audiences = this.editable_audiences.filter(a => a !== audienceId)
      } else {
        this.editable_audiences.push(audienceId)
      }
    },
    save_targeting () {
      if (this.editable_audiences.length !== 0) {
        this.addNotification({ body: 'You must save audience', type: 'danger' })
      } else {
        this.create_audience({ campaignId: this.getCampaign.id, payload: { audiences_data: this.new_audiences } })
          .then(() => {
            this.set_local_audiences_data(null)
            this.$router.push({ name: 'NewCampaignAdDesign' })
          })
          .catch(er => {
            if (er.response.data.errors) {
              this.addNotification({
                body: `${er.response.data.errors[0].detail}`,
                type: 'danger'
              })
            }
            if (er.response.data.error_message) {
              this.addNotification({
                body: `${er.response.data.error_message}`,
                type: 'danger'
              })
            }
          })
      }
    },
    add_new_audience () {
      const id = Date.now()
      this.new_audiences.push({
        id: id,
        audiences: [],
        locations: [],
        age_min: 18,
        age_max: 65,
        genders: 0
      })
      this.toggle_audience_editable(id)
      this.set_local_audiences_data(this.new_audiences)
    },
    remove_audience (audienceId) {
      this.new_audiences = this.new_audiences.filter(a => a.id !== audienceId)
      this.set_local_audiences_data(this.new_audiences)
      this.editable_audiences = this.editable_audiences.filter(a => a !== audienceId)
    }
  }
})
